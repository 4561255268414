import React from "react";

function SocialButton(prop) {
    return ( 
        <a className="social-btn" href={prop.link} target="_blank">
            <img src={prop.imgSrc} alt="" />
        </a>
    );
}

export default SocialButton;