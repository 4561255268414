import React from 'react';
import './StorySection.scss';


function StorySection() {
    return (
        <section className="story-section" id="story">
            <div className="content container offset-md-1 offset-lg-1">
                <h2>Storytelling</h2>
                <p>coming soon....</p>
            </div>
        </section>
    );
}

export default StorySection;