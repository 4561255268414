import { React, useState } from 'react';
import c from 'classnames'
import logo from '../../assets/gdeformer_logo.svg';
import './Navigation.scss';

function Navigation() {
    const [expand, setExpand] = useState(false)
    const accordionClick = (e) => {
        e.preventDefault();
        setExpand(!expand);
        console.log(expand);
    }

    var btnClass = c({
        'Nav-menu': true,
        'expanded': expand,
        'collapsed': !expand
    });

    var arrowClass = c({
        'arrow':true,
        'down': !expand,
        'up': expand
    });

    return (
        <div className="Nav-container">
          <div className="logo-container"> 
            <img src={logo} className="App-logo" alt="logo" />
            <hr className="solid"/>
          </div>
          <nav>
            <button className="Expand-collapse-btn" onClick={accordionClick}> <i className={arrowClass}></i></button>
            <ul className={btnClass}>
              <li><a href="#code">Code</a></li>
              <li><a href="#design">Design</a></li>
              <li><a href="#story">Storytelling</a></li>
            </ul>
          </nav>
        </div>
    );
}

export default Navigation;