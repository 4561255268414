import React from 'react';
import './Splash.scss'
import { ReactComponent as PurpleBlob } from '../../../assets/splash/purple-blob.svg';
import { ReactComponent as BlueBlob } from '../../../assets/splash/blue-blob.svg';
import { ReactComponent as OrangeBlob } from '../../../assets/splash/orange-blob.svg';
import { ReactComponent as GreenBlob } from '../../../assets/splash/green-blob.svg';
import { ReactComponent as WhiteBlob } from '../../../assets/splash/white-blob.svg';
import { ReactComponent as FloatingBlob } from '../../../assets/splash/small-floating-purple-blob.svg';
import { ReactComponent as LetterROne } from '../../../assets/splash/R1.svg';
import { ReactComponent as LetterE } from '../../../assets/splash/E.svg';
import { ReactComponent as LetterS } from '../../../assets/splash/S.svg';
import { ReactComponent as LetterTOne } from '../../../assets/splash/T1.svg';
import { ReactComponent as LetterA } from '../../../assets/splash/A.svg';
import { ReactComponent as LetterRTwo } from '../../../assets/splash/R2.svg';
import { ReactComponent as LetterTTwo } from '../../../assets/splash/T2.svg';

import { ReactComponent as KeyP } from '../../../assets/splash/key-p.svg';
import { ReactComponent as KeySquare } from '../../../assets/splash/key-square-bracket.svg';
import { ReactComponent as KeyArrow } from '../../../assets/splash/key-left-arrow.svg';
import { ReactComponent as KeyEight } from '../../../assets/splash/key-8.svg';
import purposeCode from '../../../assets/splash/coding.png';

function Splash(prop) {
    const customTranslateYStyle = (speed = 0.25, dir = 1) => {
        return {transform: `translateY(${prop.offset * speed * dir}px)`, }
    };

    const purposeTranslateYStyle = (speed = 0.25, dir = 1) => {
        return {transform: `translateY(${prop.offset * speed * dir}px) skew(-10deg)`, }
    };

    return (
        <section className="splash-container">
            <div className="svg-container">
                <PurpleBlob className="purple-blob absolute-elem" style={customTranslateYStyle(0.25, -1)}/>
                <OrangeBlob className="orange-blob absolute-elem" style={customTranslateYStyle(0.25, -1)}/>
                
                <GreenBlob className="green-blob absolute-elem" style={customTranslateYStyle(0.18, -1)}/>
                <div className="overlow-control-container">
                    <BlueBlob className="blue-blob absolute-elem" style={customTranslateYStyle(0.25, -1)}/>
                    <WhiteBlob className="white-blob absolute-elem" style={customTranslateYStyle(0.12, -1)}/>
                </div>
                <LetterROne className="letter-r-one absolute-elem" style={customTranslateYStyle(0.3, -1)}/>
                <LetterE className="letter-e absolute-elem" style={customTranslateYStyle(0.22, -1)}/>
                <LetterS className="letter-s absolute-elem" style={customTranslateYStyle(0.4, -1)}/>
                <LetterTOne className="letter-t-one absolute-elem" style={customTranslateYStyle(0.48, -1)}/>
                <LetterA className="letter-a absolute-elem" style={customTranslateYStyle(0.45, -1)}/>
                <LetterRTwo className="letter-r-two absolute-elem" style={customTranslateYStyle(0.2, -1)}/>
                <LetterTTwo className="letter-t-two absolute-elem" style={customTranslateYStyle(0.3, -1)}/>
                <FloatingBlob className="floating-blob absolute-elem" style={customTranslateYStyle(0.5, -1)}/>
                <div className="purple-overlow-control-container">
                    <div className="purple-radial radial-one"></div>
                    <div className="purple-radial radial-two"></div>
                    <div className="purple-radial radial-three"></div>
                </div>
                <KeyP className="key-p absolute-elem" style={customTranslateYStyle(0.35, -1)}/>
                <KeySquare className="key-square absolute-elem" style={customTranslateYStyle(0.7, -0.8)}/>
                <KeyArrow className="key-arrow absolute-elem" style={customTranslateYStyle(0.4, -1)}/>
                <KeyEight className="key-eight absolute-elem" style={customTranslateYStyle(0.36, -1)}/>
                <img src={purposeCode} alt="" className="purpose-code" style={purposeTranslateYStyle(0.7, -1.2)}/>
            </div>
            <div className="intro col-md-8 col-lg-6 offset-md-1 offset-lg-2" style={customTranslateYStyle(0.2)}>
                <h1><b>Gdeformer</b> - a portfolio site of Luan Ling Chiang.</h1>
                <p>A creative developer who likes to explore various coding, design, art methodologies to achieve visually interesting work. I am also rediscovering myself and this world through storytelling. Stories touch and empower people.</p>
            </div>
        </section>
    );
}

export default Splash;

