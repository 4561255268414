import React from 'react';
import './DesignSection.scss';
import poto from '../../../assets/design/poto-logo.png';
import gdeformer from '../../../assets/gdeformer_logo.svg';
import pinko from '../../../assets/design/pinko-logo.png';
import pinkoCover from '../../../assets/design/pinko-cover.jpeg';

function DesignSection() {
    return (
        <section className="design-section" id="design">
            <div className="purple-radial"></div>
            <div className="content container offset-md-1 offset-lg-1">
                <h2>Design</h2>
                <div className="design-sub-section">
                    <p className="sub-title">Identities</p>
                    <div className="row">
                        <div className="logo-container col-md-3"><img src={poto} alt="" className="identity-logo"/></div>
                        <div className="logo-container col-md-3"><img src={gdeformer} alt="" className="identity-logo"/></div>
                        <div className="logo-container col-md-3"><img src={pinko} alt="" className="identity-logo"/></div>
                    </div>
                </div>
                <div className="design-sub-section">
                    <p className="sub-title">Album Cover</p>
                    <div className="cover-container">
                        <img src={pinkoCover} alt="" className="cover"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DesignSection;