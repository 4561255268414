import React from 'react';
import './CodeSection.scss';
import Desigual from '../../../assets/clients/desigual-logo.jpg';
import CK from '../../../assets/clients/Ck-logo.png';
import TE from '../../../assets/clients/te-logo.png';
import MME from '../../../assets/clients/mercedes-me-web-logo.png';
import Olympus from '../../../assets/clients/olympus.png';
import Firstborn from '../../../assets/clients/firstborn-logo.png';
import AKQA from '../../../assets/clients/akqa.png';
import Sapient from '../../../assets/clients/sapient-razorfish.png';
import MeApp from '../../../assets/appIcons/MME-app-icon.png';
import PhApp from '../../../assets/appIcons/PlanHub-Icon-V1-60.png';
import {ReactComponent as Curve} from '../../../assets/code-curve.svg';
import PdfIcon from '../../../assets/appIcons/pdf-icon.png';
import GithubIcon from '../../../assets/appIcons/GitHub-Mark-64px.png';
import LinkedinIcon from '../../../assets/appIcons/LI-In-Bug.png';

import SocialButton from './SocialButton';

function CodeSection(prop) {
    const customTranslateXStyle = (prop, speed = 0.25, dir = 1) => {
        return {transform: `translateX(${prop.offset * speed * dir}px)`,}
    }

    return (
        <section className="code-section" id="code">
            <Curve className="purple-curve"/>
            <div className="content container offset-md-1 offset-lg-1">
                <h2>Code</h2>
                <p className="col-sm-12 col-md-8">More than 10 years Experienced in web and mobile development.</p>
                <p className="col-sm-12 col-md-8"><b>Web:</b> Javascript, ES6, CSS (SASS), HTML, React.js, Angular etc.<br />Past clients through agencies are:</p>
                <div className="clients">
                    <img src={Desigual} alt=""/>
                    <img src={CK} alt=""/>
                    <img src={TE} alt=""/>
                    <img src={MME} alt=""/>
                    <img src={Olympus} alt=""/>
                    <p>and more.</p>
                </div>
                <p className="col-sm-12 col-md-8"><b>Mobile:</b> Native iOS cocoa framework development, UIKit, SwiftUI, Combine, Rich push notification service extension, SiriKit, and WatchKit POC, etc.</p>
                <div className="row">
                    <div className="appIcons col">
                        <p>Past clients through agencies are:</p>
                        <a href="https://apps.apple.com/us/app/mercedes-me-usa/id1132286368" target="_blank" rel="noopener noreferrer">
                            <img src={MeApp} alt=""/>
                        </a>
                        <p>Mercedes Me</p>
                    </div>	
                    <div className="appIcons col">
                        <p className="col">Personal iOS app:</p>
                        <img src={PhApp} alt=""/>
                        <p>Plan Hub (coming soon)</p>
                    </div>				
                </div>
                <div>
                    <h2>Thanks to agencies:</h2>
                    <div className="agencies">
                        <img src={Firstborn} alt=""/>
                        <img src={AKQA} alt=""/>
                        <img src={Sapient} alt=""/>
                    </div>

                    <p>More details about me and contact:</p>
                    <div>
                        <SocialButton imgSrc={PdfIcon} link="https://gdeformer.com/Luan-Ling-Chiang-Resume.pdf"/>
                        <SocialButton imgSrc={GithubIcon} link="https://github.com/Luan97"/>
                        <SocialButton imgSrc={LinkedinIcon} link="https://www.linkedin.com/in/luanling/"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CodeSection;