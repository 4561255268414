import React, { useState, useEffect } from 'react';
import Navigation from './components/navigation/Navigation'
import Splash from './components/sections/splash/Splash';
import CodeSection from './components/sections/code/CodeSection';
import DesignSection from './components/sections/design/DesignSection';
import StorySection from './components/sections/storytelling/StorySection';
import './App.scss';

function App() {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const handleScroll = ()=> {
          setOffset(window.pageYOffset);
          //console.log(offset);
      }

      window.addEventListener("scroll", handleScroll)

      return () => {
          window.removeEventListener("scroll", handleScroll)
      }
  }, [offset])

  const [mobileMode, setMobileMode] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          if (window.innerWidth < 768) {
              setMobileMode(true);
          } else {
              setMobileMode(false);
          }
          //console.log(mobileMode);
      };
      handleResize()
      window.addEventListener("resize", handleResize, { passive: true });
      return () => window.removeEventListener("resize", handleResize);
  }, [mobileMode]);
  
  return (
      <div className="App" >
        <header className="App-header container">
          <Navigation />
        </header>
        <div className="App-content">
            <Splash offset={offset} mobileMode={mobileMode}/>
            <CodeSection />
            <DesignSection />
            <StorySection />
          </div>
      </div>
  );
}

export default App;
